<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="tahun" label="Tahun">
            <a-input-number v-model:value="formState.tahun" placeholder="Masukkan tahun" />
          </a-form-item>
          <a-form-item name="keterangan" label="Keterangan">
            <a-input v-model:value="formState.keterangan" placeholder="Masukkan keterangan uraian" />
          </a-form-item>
          <a-form-item label="Progress Triwulan">
            <a-row>
              <a-col :span="6">
                <a-checkbox v-model:checked="formState.tw1">TW1</a-checkbox>
              </a-col>
              <a-col :span="6">
                <a-checkbox v-model:checked="formState.tw2">TW2</a-checkbox>
              </a-col>
              <a-col :span="6">
                <a-checkbox v-model:checked="formState.tw3">TW3</a-checkbox>
              </a-col>
              <a-col :span="6">
                <a-checkbox v-model:checked="formState.tw4">TW4</a-checkbox>
              </a-col>
            </a-row>
          </a-form-item>
          <a-row style="margin-bottonm:12px;">
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/laporan-manajemen/progress-rjpp">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../../components/crud/style';
import { Main } from '../../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';

const ProgressRjppForm = {
  name: 'ProgressRjppForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);

    const formState = reactive({
      id: null,
      tahun: null,
      keterangan: null,
      tw1: false,
      tw2: false,
      tw3: false,
      tw4: false,
    });

    const labelCol = {
      lg: 4,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 20,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      let dataParams = toRaw(formState);
      console.log(dataParams);
      // return false;

      // eslint-disable-next-line no-unreachable
      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-progress-rjpp-progress',
          data: dataParams,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.itemId,
          url: 'laporan-progress-rjpp-progress',
          data: dataParams,
        });
      }
    };

    const rules = {
      keterangan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const options = reactive({
      parents: [],
    });

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      if (props.mode == 'Ubah') {
        const data = {
          url: 'laporan-progress-rjpp-progress',
          id: params.itemId,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          console.log(crud.value);
          formState.id = crud.value.id;
          formState.tahun = crud.value.tahun;
          formState.keterangan = crud.value.keterangan;
          formState.tw1 = crud.value.tw1;
          formState.tw2 = crud.value.tw2;
          formState.tw3 = crud.value.tw3;
          formState.tw4 = crud.value.tw4;
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      options,
      filterSelect,
    };
  },
};

export default ProgressRjppForm;
</script>
