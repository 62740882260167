<template>
  <ProgressRjppForm mode="Ubah" module="Progess/Keterangan Uraian RJPP"> </ProgressRjppForm>
</template>

<script>
import ProgressRjppForm from './form';

const ProgessUpdateRjpp = {
  name: 'ProgessUpdateRjpp',
  components: { ProgressRjppForm },
};

export default ProgessUpdateRjpp;
</script>
